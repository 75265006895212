import React, { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AWS_IMAGE_URL, APP_ENV, HOST_BIZ } from '@src/library/Constants';
import { isMobileDevice } from '@src/library/Utils';

const PosProgram = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type') || 'program'; // program, app
  const tab = searchParams.get('tab') || 'download'; // download, guid
  const defalutSubTab = tab === 'download' ? 'pc' : 'login';
  const subTab = searchParams.get('subTab') || defalutSubTab; // login, save, setting
  const downRef = useRef(null);

  const downUrl =
    APP_ENV === 'production'
      ? 'https://wmpo2o-cupping.s3.ap-northeast-2.amazonaws.com/attach/pomi_pos/download/PopoPosSetup_1_0_1.msi'
      : 'https://o2olab-staging.s3.ap-northeast-2.amazonaws.com/attach/pomi_pos/download/PopoPosSetup_1_0_1.msi';

  const handleTab = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;
    setSearchParams({ type, tab: value });
  };

  const handleSubTab = (e: React.MouseEvent<HTMLButtonElement>) => {
    window.scrollTo({
      top: 0,
    });
    const { value } = e.currentTarget;
    setSearchParams({ type, tab, subTab: value });
  };

  const handleDownload = () => {
    if (isMobileDevice()) {
      alert('포포 사장님 프로그램은 모바일 환경에서 다운로드 불가합니다. \n 매장의 POS 또는 윈도우 PC에 설치해주세요.');
    } else {
      downRef.current.click();
    }
  };

  return (
    <>
      <div className="tab-guide">
        <ul role="tablist">
          <li>
            <button type="button" value="download" data-on={tab === 'download'} onClick={handleTab}>
              <span className="txt-tab">다운로드</span>
            </button>
          </li>
          <li>
            <button type="button" value="guid" data-on={tab === 'guid'} onClick={handleTab}>
              <span className="txt-tab">이용 가이드</span>
            </button>
          </li>
        </ul>
      </div>
      <article data-show={tab === 'download'}>
        <div className="list-guide" role="tabpanel">
          <ul id="tabList" role="tablist">
            <li>
              <button type="button" value="pc" data-on={subTab === 'pc'} className="link-guide " onClick={handleSubTab}>
                <span className="txt-tab">PC 프로그램</span>
              </button>
            </li>
          </ul>
        </div>
        <div className="panel-guide">
          <section className="guide-section">
            <div id="pos-tab-7" className="pos-tab-content on">
              <div className="box-pos-latest">
                <div className="info-pos">
                  <div>
                    <h4 className="tit-pos">사장님 프로그램</h4>
                    <p className="desc-pos">
                      포미포인트 사장님 프로그램을 이용하여 오프라인에서 포미포인트 적립/사용 처리를 해보세요.
                      <br />
                      매장의 POS 또는 윈도우 PC에 설치 후 사용할 수 있습니다.
                    </p>
                  </div>
                </div>
                <img
                  src={`${AWS_IMAGE_URL}/image/00-popo-ownerguide-appdownload@3x.png`}
                  alt="포포 프로그램 사용 이미지"
                  className="pullImg"
                />
                <button type="button" className="btn-download" onClick={handleDownload}>
                  최신버전 다운로드
                  <span className="icon-download" />
                </button>
                <a href={downUrl} className="btn-download-a" ref={downRef} target="_blank" download rel="noreferrer">
                  최신버전 다운로드
                </a>
              </div>
            </div>
            <div id="pos-tab-7-sub" className="pos-tab-sub">
              <h4 className="tit-guide-info">설치 사양</h4>
              <ul className="list-spec">
                <li>
                  <strong className="tit-spec">POS</strong>
                  <p className="desc-spec">POS Ready 7 / Windows 7 이상</p>
                </li>
                <li>
                  <strong className="tit-spec">PC</strong>
                  <p className="desc-spec">Windows 7 이상</p>
                </li>
              </ul>
            </div>
          </section>
          <button
            type="button"
            className="btn-download"
            onClick={() => {
              window.location.href = 'https://print-projects.netlify.app/';
            }}
          >
            출력 테스트
          </button>
        </div>
      </article>
      <article data-show={tab === 'guid'}>
        <div className="list-guide" role="tabpanel">
          <ul>
            <li>
              <button
                type="button"
                value="login"
                data-on={subTab === 'login'}
                className="link-guide "
                onClick={handleSubTab}
              >
                <span className="txt-tab">로그인</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                value="save"
                data-on={subTab === 'save'}
                className="link-guide "
                onClick={handleSubTab}
              >
                <span className="txt-tab">포인트 적립</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                value="setting"
                data-on={subTab === 'setting'}
                className="link-guide "
                onClick={handleSubTab}
              >
                <span className="txt-tab">포인트 조건 설정</span>
              </button>
            </li>
          </ul>
        </div>
        <div className="panel-guide" data-show={subTab === 'login'}>
          <ul className="list-info">
            <li>사장님 프로그램을 설치 후 실행하면 아래와 같은 로그인 화면이 보여집니다.</li>
          </ul>
          <ol className="list-steps">
            <li>
              <strong className="tit-step">
                <em>STEP 1</em> 로그인
              </strong>
              <p className="desc-step">
                위메프오 파트너스 사이트(https://partner.wmpo.co.kr)에서 가입한 아이디와 비밀번호를 입력해주세요.
              </p>
              <img src={`${AWS_IMAGE_URL}/image/00-popo-ownerguide-img-01-1@3x.jpg`} className="img-step" alt="" />
            </li>
            <li>
              <strong className="tit-step">
                <em>STEP 2</em> 로그인 후 사장님 프로그램 실행
              </strong>
              <p className="desc-step">
                로그인과 동시에 포미포인트 사장님 프로그램이 실행됩니다. 포미포인트를 클릭하여 포인트 적립을 해보세요.
              </p>
              <img src={`${AWS_IMAGE_URL}/image/00-popo-ownerguide-img-01-2@3x.jpg`} className="img-step" alt="" />
            </li>
          </ol>
        </div>
        <div className="panel-guide" data-show={subTab === 'save'}>
          <ul className="list-info">
            <li>사장님 프로그램에서 오프라인 결제 고객 대상으로 포인트를 적립할 수 있습니다.</li>
          </ul>
          <ol className="list-steps">
            <li>
              <strong className="tit-step">
                <em>STEP 1</em> 포미포인트 클릭
              </strong>
              <p className="desc-step">포미포인트를 클릭하여 포인트 적립 창을 실행합니다.</p>
              <img src={`${AWS_IMAGE_URL}/image/00-popo-ownerguide-img-02-1@3x.jpg`} className="img-step" alt="" />
            </li>
            <li>
              <strong className="tit-step">
                <em>STEP 2</em> 결제 금액 입력
              </strong>
              <p className="desc-step">
                오프라인 매장에서 고객이 주문한 결제 금액을 입력해주세요.
                <br />
                [확인] 버튼 클릭 시 적립 포인트를 확인할 수 있습니다.
              </p>
              <img src={`${AWS_IMAGE_URL}/image/00-popo-ownerguide-img-02-2@3x.jpg`} className="img-step" alt="" />
            </li>
            <li>
              <strong className="tit-step">
                <em>STEP 3</em> 휴대폰 번호 입력
              </strong>
              <h5 className="tit-info">
                <em>1</em>사장님이 직접 휴대폰 번호 입력
              </h5>
              <p className="desc-step">
                매장 APP 사용 설정이 OFF 처리된 경우 사장님이 직접 휴대폰 번호를 입력해야 합니다.
                <br />
                고객에게 휴대폰 번호 확인 후 휴대폰 번호를 입력합니다.
                <br />
                휴대폰 번호 입력까지 완료되면 [적립하기] 버튼을 눌러주세요.
              </p>
              <img src={`${AWS_IMAGE_URL}/image/00-popo-ownerguide-img-02-3@3x.jpg`} className="img-step" alt="" />
              <h5 className="tit-info">
                <em>2</em>고객이 직접 휴대폰 번호 입력
              </h5>
              <p className="desc-step">
                매장 APP 사용 설정이 ON 처리된 경우 매장 APP을 통해 고객이 직접 휴대폰 번호를 입력해야 합니다.
                <br />
                결제 금액 입력 후 [적립하기] 버튼을 누르면 포인트 적립 창이 닫히며 매장 APP으로 휴대폰 번호 입력
                요청됩니다.
              </p>
            </li>
            <li>
              <strong className="tit-step">
                <em>STEP 4</em> 포인트 적립
              </strong>
              <p className="desc-step">휴대폰 번호 입력이 완료되면 입력한 휴대폰 번호로 포인트 적립이 완료됩니다. </p>
              <div className="box-qna">
                <strong className="tit-question">포인트 적립 조건 설정 변경은 어떻게 하나요?</strong>
                <p className="desc-anwser">
                  ·기본 적립 조건 설정 변경은 파트너스 사이트의 [포인트관리] – [오프라인 포인트 설정]에서 가능합니다.
                  <br /> ·포인트 적립하는 과정에서 적립 조건 설정 변경은 포인트 적립 창 내 ‘적립 포인트‘ 영역 클릭 시
                  노출되는 ‘적립 방법 ‘, ‘유효기간’ 입력 영역을 클릭하여 변경합니다.
                </p>
              </div>
            </li>
          </ol>
        </div>
        <div className="panel-guide" data-show={subTab === 'setting'}>
          <ul className="list-info">
            <li>포인트 조건에 따라 고객에게 적립되는 포인트가 설정됩니다.</li>
            <li>
              우측 설정 아이콘 클릭 &gt; 포인트 조건 설정 클릭 시 파트너스의 ‘오프라인 포인트 설정’ 페이지가 실행되며,
              해당 페이지에서 설정이 가능합니다.
            </li>
          </ul>
          <ol className="list-steps">
            <li>
              <strong className="tit-step">
                <em>STEP 1</em> 설정 아이콘 클릭
              </strong>
              <img src={`${AWS_IMAGE_URL}/image/00-popo-ownerguide-img-03-1@3x.jpg?_=1`} className="img-step" alt="" />
            </li>
            <li>
              <strong className="tit-step">
                <em>STEP 2</em> [포인트 조건 설정] 버튼 클릭
              </strong>
              <img src={`${AWS_IMAGE_URL}/image/00-popo-ownerguide-img-03-2@3x.jpg`} className="img-step" alt="" />
            </li>
            <li>
              <strong className="tit-step">
                <em>STEP 3</em> 매장 선택 후 [수정] 버튼 클릭
              </strong>
              <img src={`${AWS_IMAGE_URL}/image/00-popo-ownerguide-img-03-3@3x.jpg`} className="img-step" alt="" />
            </li>
            <li>
              <strong className="tit-step">
                <em>STEP 4</em> 포인트 조건 설정
              </strong>
              <p className="desc-step">
                포인트 적립 방법, 유효기간, 최대 적립 금액 설정 변경이 가능합니다. <br />
                모든 설정이 완료되면 하단의 [저장] 버튼을 클릭하여 설정을 저장해주세요.
              </p>
              <img src={`${AWS_IMAGE_URL}/image/00-popo-ownerguide-img-03-4@3x.jpg`} className="img-step" alt="" />
            </li>
          </ol>
        </div>
      </article>
    </>
  );
};

export default PosProgram;
